module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-48b781198a/0/cache/gatsby-plugin-styled-components-npm-5.7.0-7a95b4199a-3c2845d267.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-a02be3dc5d/0/cache/gatsby-plugin-google-analytics-npm-4.7.0-ec7b44c4eb-8224c06767.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-PDDE6HWRCF","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-f8ec56620b/0/cache/gatsby-plugin-manifest-npm-4.7.0-641c792edd-94c57f4647.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9fe5b4af3df82909c91821c259f9581"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-56a3f41e84/0/cache/gatsby-plugin-mdx-npm-3.7.1-2a077d89d3-9bf8ca7469.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/rnolan3/Developer/happitu/clamps","commonmark":false},
    }]
